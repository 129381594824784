define("discourse/plugins/attachments-list/discourse/controllers/admin-attachments", ["exports", "@ember/controller", "@ember/service", "@ember/object", "discourse-common/utils/decorators", "@ember-decorators/object", "discourse-common/lib/debounce", "discourse/plugins/attachments-list/discourse/models/attachment", "discourse-common/config/environment", "discourse/lib/computed"], function (_exports, _controller, _service, _object, _decorators, _object2, _debounce, _attachment, _environment, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AdminAttachmentsController = _exports.default = (_dec = (0, _computed.i18n)("admin.uploads.search_hint"), _dec2 = (0, _object2.observes)("listFilter"), (_class = class AdminAttachmentsController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _defineProperty(this, "listFilter", null);
      _defineProperty(this, "model", null);
      _defineProperty(this, "query", null);
      _defineProperty(this, "order", null);
      _defineProperty(this, "asc", null);
      _defineProperty(this, "showEmails", false);
      _defineProperty(this, "refreshing", false);
      _defineProperty(this, "listFilter", null);
      _defineProperty(this, "selectAll", false);
      _defineProperty(this, "_page", 1);
      _defineProperty(this, "_results", []);
      _defineProperty(this, "_canLoadMore", true);
      _initializerDefineProperty(this, "searchHint", _descriptor2, this);
    }
    _filterUsers() {
      (0, _debounce.default)(this, this.resetFilters, _environment.INPUT_DELAY);
    }
    resetFilters() {
      this._page = 1;
      this._results = [];
      this._canLoadMore = true;
      this._refreshUploads();
    }
    _refreshUploads() {
      if (!this._canLoadMore) {
        return;
      }
      const page = this._page;
      this.set("refreshing", true);
      _attachment.default.findAll({
        filter: this.listFilter,
        order: this.order,
        asc: this.asc,
        page
      }).then(result => {
        this._results[page] = result;
        this.set("model", this._results.flat());
        if (result.length === 0) {
          this._canLoadMore = false;
        }
      }).finally(() => {
        this.set("refreshing", false);
      });
    }
    loadMore() {
      this._page += 1;
      this._refreshUploads();
    }
    columnCount() {
      let colCount = 5; // note that the first column is hardcoded in the template

      return colCount;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "searchHint", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "_filterUsers", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "_filterUsers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadMore", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadMore"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "columnCount", [_decorators.default], Object.getOwnPropertyDescriptor(_class.prototype, "columnCount"), _class.prototype)), _class));
});